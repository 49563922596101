import { Button, Grid, Link, TextField, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { login } from "../../services/api";

interface LoginComponentProps {
    onSuccess: (user: any) => void;
    onError: (message: string, errorMessage: string) => void;
}

const LoginComponent = ({ onError, onSuccess }: LoginComponentProps) => {

    const [isLoading, setLoading] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const theme = useTheme();
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });
    const [errors, setErrors] = useState({
        username: false,
        password: false
    });

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        if (!executeRecaptcha) {
            console.log("Recaptcha no está disponible");
            setLoading(false);
            return;
        }
        const token = await executeRecaptcha();
        const newErrors = {
            username: formData.username === '',
            password: formData.password === ''
        };
        setErrors(newErrors);
        if (Object.values(newErrors).some((error) => error)) {
            setLoading(false);
            return;
        }
        try {
            const user = await login({
                username: formData.username,
                password: formData.password,
                token
            });
            onSuccess(user);
            setLoading(false);
        } catch (err: any) {
            onError(err.message, err.details);
            setLoading(false);
        }
    }

    const handleChange = async (e: any) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <Typography component="h4" variant="h4">
                        Ingreso
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Nombre de Usuario"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                        error={errors.username}
                        helperText={errors.username ? 'El nombre de usuario es obligatorio' : ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Contraseña"
                        name="password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                        error={errors.password}
                        helperText={errors.password ? 'La contraseña es obligatoria' : ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        disabled={isLoading}
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: theme.spacing(2) }}
                    >
                        Ingresar
                    </Button>
                </Grid>
            </Grid>
            <br />
            <Typography variant="body2" component="span" >¿No tienes cuenta? <Link href="/registro">Regístrate aquí</Link></Typography>
            <br />
            <Typography variant="body2" component="span" >¿Problemas con tu contraseña? <Link href="/registro">Recupera tu contraseña</Link></Typography>
        </form>
    )
}

export default LoginComponent;