import { Box, Paper, Skeleton, Stepper, useTheme } from "@mui/material";
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { validateJwt } from "../../services/api";
import { UserInterface } from "../../utils/models";
import { getCookie } from "../../utils/tokens";
import ErrorDialog from "../dialogs/ErrorDialog";
import SuccessMessage from "../utils/SuccessMessage";
import GenerateEmailCode from "./GenerateEmailCode";
import GenerateSmsCode from "./GenerateSmsCode";
import PersonalDataRegisterForm from "./PersonalDataRegisterForm";

const steps = ['Crear usuario', 'Validar correo', 'Validar teléfono'];

const RegisterComponents = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [createdUser, setCreatedUser] = useState<UserInterface | undefined>();
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [error, setError] = useState("");
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const [user, setUser] = useState();

    const successTitle = "¡Tu cuenta está lista!";
    const successMessage = "¡Genial! Tu cuenta se ha creado con éxito. Ya puedes empezar a disfrutar de nuestros servicios.";

    useEffect(() => {
        const validatesJwt = getCookie('JWT_VALIDATES') === "true";
        if (validatesJwt) jwtValidationHandler();
        else setLoading(false);
    }, []);

    const jwtValidationHandler = async () => {
        const resp = await validateJwt();
        if (resp.name !== null) setCurrentStep(2);
        if (resp.emailValid) setCurrentStep(3);
        setLoading(false);
    }

    const nextStepUser = (user: UserInterface) => {
        setCreatedUser(user);
        setCurrentStep(2);
    };
    const handleErrorMessage = (error: string, errorMessage: string) => {
        setError(error);
        setErrorMessage(errorMessage);
        setShowErrorDialog(true);
    }

    const handleRegisterSuccess = (user: any) => {
        setRegisterSuccess(true);
        setCurrentStep(5);
        setUser(user);
    }

    return (
        <>
            <ErrorDialog
                open={showErrorDialog}
                onClose={() => setShowErrorDialog(false)}
                errorDescription={errorMessage}
                errorName={error} />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        padding: theme.spacing(4),
                        maxWidth: 500,
                        width: '100%',
                    }}
                >

                    {!registerSuccess && <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Stepper style={{ marginBottom: 20 }} activeStep={currentStep - 1}>
                            {steps.map((label) => {
                                const stepProps: { completed?: boolean } = {};
                                const labelProps: {
                                    optional?: React.ReactNode;
                                } = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </motion.div>}
                    {loading && <>
                        <Skeleton height={100} />
                        <Skeleton height={100} />
                    </>}
                    {(!loading && currentStep === 1) && <PersonalDataRegisterForm onError={handleErrorMessage} nextStep={nextStepUser} />}
                    {(!loading && currentStep === 2) && <GenerateEmailCode nextStep={() => setCurrentStep(3)} user={createdUser} onError={handleErrorMessage} />}
                    {(!loading && currentStep === 3) && <GenerateSmsCode onRegisterSuccess={handleRegisterSuccess} onError={handleErrorMessage} user={createdUser} />}
                    {registerSuccess && <SuccessMessage user={user} withCountDown={5} title={successTitle} message={successMessage} redirectTo="/" />}
                </Paper>
            </Box >
        </>
    )
}

export default RegisterComponents;
