import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FeaturesInterface } from "../../utils/models";

interface FilterFeatureProps {
    features: FeaturesInterface[];
    onChange: (value: number) => void;
    selectedFeature: number;
}

const FilterFeature = ({ features, onChange, selectedFeature }: FilterFeatureProps) => {
    return (
        <FormControl style={{ marginTop: 10 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Feature</InputLabel>
            <Select
                value={selectedFeature || ""}
                onChange={(evt: any) => onChange(evt.target.value)}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Feature"
            >
                <MenuItem value="">Todos</MenuItem>
                {features?.map((feature) => (
                    <MenuItem key={feature.id} value={feature.id}>
                        {feature.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default FilterFeature;
