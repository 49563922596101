import { motion } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const AuthSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const images = ["luffychiquito.jpg", "aceaa.jpg", "sabo.jpg", "garpaa.jpg", "kiku.jpg"]

    return (
        <Slider autoplay={true} {...settings}>
            {images.map((image) => (
                <motion.div
                    style={{
                        display: 'inline-block',
                        overflow: 'hidden'
                    }}
                    whileHover={{ scale: 0.8 }}
                    transition={{ duration: 0.3 }}
                >
                    <img style={{ height: "90vh", margin: 20, borderRadius: '2.5%', marginLeft: "2.5vw" }} src={image} alt="Imagen de carta 1" />
                </motion.div>
            ))}
        </Slider>
    );
};

export default AuthSlider;
