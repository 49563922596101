import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Container, Dialog, DialogContent, Fab, Paper, Tab, Tabs } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRouteLoaderData } from "react-router-dom";
import CardDialog from "../components/dialogs/CardDialog";
import Filters from '../components/filters/Filters';
import Auctions from '../components/home/Auctions';
import Sets from '../components/home/Sets';
import Stock from '../components/home/Stocks';
import WatchAllCards from '../components/home/WatchAllCards';
import { paginateAuctionsWithFilters, paginateCardsWithFilters, paginateSetsWithoutFilters, paginateStocksWithFilters } from "../services/api";
import { HandlePaginationInterface } from '../utils/models';

type PaginateFunction = (
    page: string,
    filters: HandlePaginationInterface
) => Promise<any>;

const Buy = () => {
    const { cardsFetched, colors, series, attributes, features }: any = useRouteLoaderData("root") || {};
    const [card, setCard] = useState();
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [cards, setCards] = useState<any>(cardsFetched);
    const [loading, showLoading] = useState(false);
    const listRef = useRef<HTMLDivElement>(null);
    const [showMobileFilters, setShowModalFilters] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);

    const [currentFilters, setCurrentFilters] = useState({
        selectColorId: 0,
        selectedCardsName: '',
        selectedFeature: 0,
        selectedSerie: 0,
        selectedCounter: '',
        selectedLanguage: "es",
        attributesIds: ''
    });

    const [appliedFilters, setAppliedFilters] = useState(currentFilters);
    const [languageValue, setLanguageValue] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState(1);

    const handlePaginationChange = useCallback(
        async (
            _evt: any,
            page: number,
            paginate: PaginateFunction
        ) => {
            showLoading(true);
            setCurrentPage(page);
            const filters: HandlePaginationInterface = {
                colorId: appliedFilters.selectColorId,
                name: appliedFilters.selectedCardsName,
                featureId: appliedFilters.selectedFeature,
                serieId: appliedFilters.selectedSerie,
                counter: appliedFilters.selectedCounter,
                attributesIds: appliedFilters.attributesIds
            };
            const cardsFetched = await paginate((page - 1).toString(), filters);
            setCards(cardsFetched);
            showLoading(false);
        }, [appliedFilters]);

    useEffect(() => {
        if (!cardsFetched || cardsFetched.length === 0) {
            switch (selectedTab) {
                case 0:
                    handlePaginationChange(null, currentPage, paginateStocksWithFilters);
                    break;
                case 1:
                    handlePaginationChange(null, currentPage, paginateAuctionsWithFilters);
                    break;
                case 3:
                    handlePaginationChange(null, currentPage, paginateCardsWithFilters);
                    break;
            }
        }
    }, [handlePaginationChange, currentPage, cardsFetched, selectedTab]);

    useEffect(() => {
        if (!isDefaultFilters(appliedFilters) && currentPage !== null) {
            switch (selectedTab) {
                case 0:
                    handlePaginationChange(null, currentPage, paginateStocksWithFilters);
                    break;
                case 1:
                    handlePaginationChange(null, currentPage, paginateAuctionsWithFilters);
                    break;
                case 3:
                    handlePaginationChange(null, currentPage, paginateCardsWithFilters);
                    break;
            }
        }
    }, [appliedFilters, currentPage, handlePaginationChange, selectedTab]);

    const isDefaultFilters = (appliedFilters: any): boolean => (
        appliedFilters.selectColorId === 0 &&
        appliedFilters.selectedCardsName === "" &&
        appliedFilters.selectedFeature === 0 &&
        appliedFilters.selectedSerie === 0 &&
        appliedFilters.selectedCounter === "" &&
        appliedFilters.selectedLanguage === "es" &&
        appliedFilters.attributesIds === ""
    );

    useEffect(() => {
        if (cards?.content) {
            showLoading(false);
            listRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [cards]);

    const handleOpenDialog = (card: any) => {
        setCard(card);
        setDialogOpen(true);
    };

    const handleFilterChange = useCallback((key: string, value: any) => {
        setCurrentFilters((prev) => ({
            ...prev,
            [key]: value
        }));
    }, []);
    const handleTabChange = (_event: React.SyntheticEvent, value: any) => {
        setCurrentFilters({
            selectColorId: 0,
            selectedCardsName: '',
            selectedFeature: 0,
            selectedSerie: 0,
            selectedCounter: '',
            selectedLanguage: "es",
            attributesIds: ''
        })
        switch (value) {
            case 0:
                handlePaginationChange(null, currentPage, paginateStocksWithFilters);
                break;
            case 1:
                handlePaginationChange(null, currentPage, paginateAuctionsWithFilters);
                break;
            case 2:
                handlePaginationChange(null, currentPage, paginateSetsWithoutFilters);
                break;
            case 3:
                handlePaginationChange(null, currentPage, paginateCardsWithFilters);
                break;
            default:
                break;
        }
        setSelectedTab(value);
    }

    return (
        <>
            <Dialog sx={{ display: { xs: 'block', md: 'none' } }} fullScreen open={showMobileFilters} onClose={() => setShowModalFilters(false)}>
                <DialogContent>
                    <Filters
                        onClick={() => {
                            setAppliedFilters(currentFilters);
                            setCurrentPage(1);
                            setShowModalFilters(false);
                        }}
                        attributes={attributes}
                        colors={colors}
                        currentFilters={currentFilters}
                        features={features}
                        series={series}
                        onChangeName={(evt) => handleFilterChange('selectedCardsName', evt.target.value)}
                        onChangeFeature={(value: number) => handleFilterChange('selectedFeature', value)}
                        onChangeColor={(value: number) => handleFilterChange('selectColorId', value)}
                        onChangeSerie={(value: number) => handleFilterChange('selectedSerie', value)}
                        onChangeCounter={(value: string) => handleFilterChange('selectedCounter', value)}
                        onChangeAttributes={(value: string) => handleFilterChange('attributesIds', value)}
                        languageValue={languageValue}
                        onChangeSwitch={(evt) => {
                            const isEsp = evt.target.checked ? "es" : "en";
                            handleFilterChange('selectedLanguage', isEsp);
                            setLanguageValue(evt.target.checked);
                        }}
                    />
                    <Fab color='primary' onClick={() => setShowModalFilters(false)} size='medium' style={{ bottom: 10, position: "fixed", right: 5 }}>
                        <CloseIcon />
                    </Fab>
                </DialogContent>
            </Dialog>
            <CardDialog card={card} open={isDialogOpen} onClose={() => setDialogOpen(false)} />
            <Paper sx={{ display: { xs: 'none', md: 'block' } }} elevation={6} style={{ width: "20%", height: "100vh", position: "absolute", top: 0, left: 0 }}>
                <Container  >
                    <Filters
                        onClick={() => {
                            setAppliedFilters(currentFilters);
                            setCurrentPage(1);
                        }}
                        attributes={attributes}
                        colors={colors}
                        currentFilters={currentFilters}
                        features={features}
                        series={series}
                        onChangeName={(evt) => handleFilterChange('selectedCardsName', evt.target.value)}
                        onChangeFeature={(value: number) => handleFilterChange('selectedFeature', value)}
                        onChangeColor={(value: number) => handleFilterChange('selectColorId', value)}
                        onChangeSerie={(value: number) => handleFilterChange('selectedSerie', value)}
                        onChangeCounter={(value: string) => handleFilterChange('selectedCounter', value)}
                        onChangeAttributes={(value: string) => handleFilterChange('attributesIds', value)}
                        languageValue={languageValue}
                        onChangeSwitch={(evt) => {
                            const isEsp = evt.target.checked ? "es" : "en";
                            handleFilterChange('selectedLanguage', isEsp);
                            setLanguageValue(evt.target.checked);
                        }} />
                </Container>
            </Paper>
            <div ref={listRef}>
                <Paper sx={{ width: { xs: '100%', md: '80%' } }} style={{ float: "right", position: "absolute", top: 0, right: 0, overflow: "scroll", height: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center' }}>
                        <Tabs value={selectedTab} aria-label="Navegador" onChange={handleTabChange}>
                            <Tab value={0} label="Stock" />
                            <Tab value={1} label="Subastas" />
                            <Tab value={2} label="Sets" />
                            <Tab value={3} label="Mirar todas las cartas" />
                        </Tabs>
                    </Box>
                    {selectedTab === 0 && <Stock
                        cards={cards}
                        currentPage={currentPage}
                        handlePaginationChange={(_evt: any, page: number) => handlePaginationChange(_evt, page, paginateStocksWithFilters)}
                        languageValue={languageValue}
                        loading={loading} />
                    }
                    {selectedTab === 1 && <Auctions
                        cards={cards}
                        currentPage={currentPage}
                        handlePaginationChange={(_evt: any, page: number) => handlePaginationChange(_evt, page, paginateStocksWithFilters)}
                        languageValue={languageValue}
                        loading={loading} />}
                    {selectedTab === 2 && <Sets
                        cards={cards}
                        currentPage={currentPage}
                        handlePaginationChange={(_evt: any, page: number) => handlePaginationChange(_evt, page, paginateCardsWithFilters)}
                        languageValue={languageValue}
                        loading={loading} />}
                    {selectedTab === 3 &&
                        < WatchAllCards
                            cards={cards}
                            currentPage={currentPage}
                            handlePaginationChange={(_evt: any, page: number) => handlePaginationChange(_evt, page, paginateCardsWithFilters)}
                            languageValue={languageValue}
                            loading={loading}
                        />
                    }
                </Paper>
            </div>
            <Fab color='primary' sx={{ display: { xs: 'block', md: 'none' } }} onClick={() => setShowModalFilters(true)} size='medium' style={{ bottom: 60, position: "fixed", right: 5 }}>
                <FilterListIcon />
            </Fab>
        </>
    );
};

export default Buy;
