import { Box, Grid, Paper, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";
import AuthSlider from "../components/auth/AuthSlider";
import LoginComponent from "../components/auth/LoginComponent";
import ErrorDialog from "../components/dialogs/ErrorDialog";
import SuccessMessage from "../components/utils/SuccessMessage";
import { getCookie } from "../services/api";

const Login = () => {
    const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    const navigate = useNavigate();
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [error, setError] = useState("");
    const [loginSuccesfull, setLoginSuccesfull] = useState(false);
    const [user, setUser] = useState();
    const theme = useTheme();

    const successTitle = "¡Bienvenido de nuevo!";
    const successMessage = "Has iniciado sesión con éxito. Ya puedes acceder a tu cuenta y disfrutar de nuestros servicios.";

    useEffect(() => {
        if (getCookie("XSRF-TOKEN") === "") {
            navigate("/");
        }
    }, [navigate]);

    const handleLoginSuccess = (user: any) => {
        setUser(user);
        setLoginSuccesfull(true);
    }

    const handleErrorMessage = (error: string, errorMessage: string) => {
        setError(error);
        setErrorMessage(errorMessage);
        setShowErrorDialog(true);
    }

    return (
        <GoogleReCaptchaProvider
            scriptProps={{
                async: true,
                defer: true,
                appendTo: 'body',
            }}
            reCaptchaKey={RECAPTCHA_SITE_KEY || ''}
        >
            <ErrorDialog
                open={showErrorDialog}
                onClose={() => setShowErrorDialog(false)}
                errorDescription={errorMessage}
                errorName={error} />
            <Grid container spacing={2} style={{ height: "94vh" }}>
                <Grid md={5}>
                    <AuthSlider />
                </Grid>
                <Grid item md={7}>
                    <motion.div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                backgroundColor: theme.palette.background.default,
                            }}
                        >
                            <Paper
                                elevation={3}
                                sx={{
                                    padding: theme.spacing(4),
                                    maxWidth: 500,
                                    width: '100%',
                                }}
                            >
                                {loginSuccesfull && <SuccessMessage user={user} withCountDown={3} title={successTitle} message={successMessage} redirectTo="/" />}
                                {!loginSuccesfull && <LoginComponent onSuccess={handleLoginSuccess} onError={handleErrorMessage} />}
                            </Paper>
                        </Box>
                    </motion.div>
                </Grid>
            </Grid>
        </GoogleReCaptchaProvider>
    )
}

export default Login;