import { CircularProgress, Grid, List, ListItem, Pagination } from "@mui/material";
import CardContentInfo from "./CardContentInfo";
interface AuctionsProps {
    cards: any;
    currentPage: number;
    languageValue: boolean;
    loading: boolean;
    handlePaginationChange: (_evt: any, page: number) => Promise<void>;
}

const Auctions = ({ cards, currentPage, handlePaginationChange, languageValue, loading }: AuctionsProps) => {
    return <>
        {loading && <CircularProgress style={{ top: "45%", position: "fixed" }} />}
        {!loading && (
            <List sx={{ bgcolor: 'background.paper' }} component="nav" aria-labelledby="nested-list-subheader">
                <ListItem>
                    <Grid style={{ margin: 0 }} color="" container spacing={3}>
                        {cards?.content.map((stock: any) => <CardContentInfo key={stock.id} isEsp={languageValue} card={stock.card} />)}
                    </Grid>
                </ListItem>
                <ListItem style={{ marginBottom: 36 }}>
                    {cards?.totalPages > 1 && (
                        <Pagination
                            onChange={handlePaginationChange}
                            size="large"
                            style={{ width: "100%", display: "flex", justifyContent: "center" }}
                            count={cards.totalPages}
                            page={currentPage}
                        />
                    )}
                </ListItem>
            </List>
        )}
    </>
}

export default Auctions;