import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";
import AuthSlider from "../components/auth/AuthSlider";
import RegisterComponents from "../components/auth/RegisterComponents";
import { getCookie } from "../utils/tokens";

const Register = () => {
    const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    const navigate = useNavigate();

    useEffect(() => {
        if (getCookie("XSRF-TOKEN") === "") {
            navigate("/");
        }
    }, [navigate]);

    return (
        <GoogleReCaptchaProvider
            scriptProps={{
                async: true,
                defer: true,
                appendTo: 'body',
            }}
            reCaptchaKey={RECAPTCHA_SITE_KEY || ''}
        >
            <Grid container spacing={2} style={{ height: "94vh" }}>
                <Grid md={5}>
                    <AuthSlider />
                </Grid>
                <Grid item md={7}>
                    <RegisterComponents />
                </Grid>
            </Grid>
        </GoogleReCaptchaProvider>
    )
}

export default Register;
