import { Card, CardContent, Grid, ImageList, ImageListItem, ImageListItemBar, Typography } from "@mui/material";
import React from "react";
import { IMAGE_BASE_URL } from "../../utils/const";

interface CardsSetContentInfoProps {
    set: any;
}

const CardsSetContentInfo = ({ set }: CardsSetContentInfoProps) => {
    return <Grid item container spacing={3} md={4} lg={3} xs={12} sm={6} alignContent="center" justifyContent="center" direction="column">
        <Card variant="outlined" style={{ width: "100%", height: "100%" }}>
            <React.Fragment>
                <ImageList sx={{ width: 500, height: 350 }} cols={2} rowHeight={350}>
                    {set.cards.map((card: any) => (
                        <ImageListItem key={card.img}>
                            <img
                                src={`${IMAGE_BASE_URL}${card.images}`}
                                alt={card.name}
                                loading="lazy"
                            />
                            <ImageListItemBar
                                title={card.name}
                                subtitle={card.serie.name}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
                <CardContent>
                    <Typography sx={{ textAlign: { xs: "center", md: "initial" } }} variant="h5" component="div">
                        {set.name}
                    </Typography>
                </CardContent>
            </React.Fragment>
        </Card>
    </Grid >
}

export default CardsSetContentInfo;