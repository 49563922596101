import { combineReducers } from '@reduxjs/toolkit';
import cardsSlice from './cardsSlice';
import userSlice from './userSlice';


const rootReducer = combineReducers({
    cards: cardsSlice,
    user: userSlice
});

export default rootReducer;