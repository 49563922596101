import { Box, Button, LinearProgress, TextField, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { generateEmailCode, validateEmailCode } from "../../services/api";
import { UserInterface } from "../../utils/models";

interface GenerateEmailCodeProps {
    onError: (error: string, errorMessage: string) => void;
    user?: UserInterface;
    nextStep: () => void;
}

const GenerateEmailCode = ({ onError, user, nextStep }: GenerateEmailCodeProps) => {
    const [code, setCode] = useState("");
    const [email, setEmail] = useState("");
    const [errorVerificationCode, setErrorVerificationCode] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [waitToSend, setWaitToSend] = useState(0);

    useEffect(() => {
        const totalDuration = 30 * 1000;
        const intervalDuration = 100;
        const incrementAmount = 100 / (totalDuration / intervalDuration);
        if (codeSent) {
            const timer = setInterval(() => {
                setWaitToSend((oldProgress) => {
                    if (oldProgress >= 100) {
                        clearInterval(timer);
                        return 100;
                    }
                    return Math.min(oldProgress + incrementAmount, 100);
                });
            }, intervalDuration);
            return () => {
                clearInterval(timer);
            };
        }
    }, [codeSent]);

    const handleSubmitVerificationCode = (event: any) => {
        event.preventDefault();
        setLoading(true);
        setErrorVerificationCode(false);
        const isValidCode = /^\d{6}$/.test(code);
        if (!isValidCode) {
            setLoading(false);
            setErrorVerificationCode(true);
        } else {
            handleValidateCode();
        }
    };

    const handleSubmitEmail = (event: any) => {
        event.preventDefault();
        setLoading(true);
        setErrorEmail(false);
        const isEmailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email);
        if (!isEmailValid) {
            setLoading(false);
            setErrorEmail(true);
        } else {
            handleSendCode();
        }
    };

    const handleSendCode = async () => {
        setWaitToSend(0);
        try {
            const resp = await generateEmailCode({
                email: email,
                username: user?.username || ''
            });
            setCodeSent(resp);
            setLoading(false);
        } catch (error: any) {
            onError(error.message, error.details);
            setLoading(false);
        }
    }

    const handleValidateCode = async () => {
        try {
            const resp = await validateEmailCode({
                email: email,
                username: user?.username || '',
                code: code
            });
            if (resp) {
                nextStep();
            }
            setLoading(false);
        } catch (error: any) {
            onError(error.message, error.details);
            setLoading(false);
        }
    }

    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                }}
            >
                <Typography variant="body1" gutterBottom>
                    {codeSent ? 'Te hemos enviado un código de verificación de 6 dígitos. Por favor, ingrésalo a continuación.' : 'Para completar el registro, es necesario validar tu correo electrónico. '}
                </Typography>
                <form onSubmit={codeSent ? handleSubmitVerificationCode : handleSubmitEmail} style={{ width: "100%" }}>
                    {codeSent ? (
                        <TextField
                            label="Código de verificación"
                            variant="outlined"
                            fullWidth
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            inputProps={{ maxLength: 6 }}
                            error={errorVerificationCode}
                            helperText={errorVerificationCode ? "Ingresa un código válido de 6 dígitos" : ""}
                        />
                    ) : (
                        <TextField
                            label="Correo electrónico"
                            variant="outlined"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={errorEmail}
                            helperText={errorEmail ? "Ingresa un correo válido" : ""}
                        />
                    )}
                    <Button
                        disabled={isLoading}
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        {codeSent ? 'Validar' : 'Enviar código'}
                    </Button>
                </form>
                {
                    codeSent &&
                    <>
                        <Button disabled={isLoading || waitToSend < 100} onClick={handleSendCode}>
                            Volver a enviar código
                        </Button>
                        <Box sx={{ width: '50%' }}>
                            <LinearProgress variant="determinate" value={waitToSend} />
                        </Box>
                    </>

                }
            </Box>
        </motion.div>
    );
}

export default GenerateEmailCode;
