import { Dialog, DialogContent } from "@mui/material";
import { useState } from "react";
import { SING_COMPONENT } from "../../utils/const";
import ForgotPassword from "../auth/ForgotPassword";
import SignIn from "../auth/SignIn";
import Signup from "../auth/Signup";

export interface SimpleDialogProps {
    open: boolean;
    onClose: (value: string) => void;
}

const SigninDialog = ({ onClose, open }: SimpleDialogProps) => {

    const [dialogComponent, setDialogComponent] = useState(1);

    const handleDialogComponentChange =
        (componentNumber: number) => setDialogComponent(componentNumber);

    return (
        <Dialog maxWidth="sm" fullWidth onClose={onClose} open={open}>
            <DialogContent>
                {dialogComponent === SING_COMPONENT.SIGN_IN ? (
                    <SignIn onClose={onClose} onDialogComponentChange={handleDialogComponentChange} />
                ) : dialogComponent === SING_COMPONENT.SIGN_UP ? (
                    <Signup onClose={onClose} onDialogComponentChange={handleDialogComponentChange} />
                ) : dialogComponent === SING_COMPONENT.FORGOT_PASS ? (
                    <ForgotPassword onClose={onClose} />
                ) : <></>}
            </DialogContent>
        </Dialog>
    )
}

export default SigninDialog;
