import axios from "axios";
import { LoaderFunction } from "react-router-dom";
import { CreateUserInterface, GenerateEmailCodeInterface, GenerateSmsCodeInterface, HandlePaginationInterface, LoginRequestInterface, PostInterface, UserAuthTokenResponse, UserInterface, ValidateEmailCode, ValidateSmsCodeInterface } from "../utils/models";

const BASE_URL = "https://api.optcgmarket.cl/";

export interface LoaderGetOnePieceDataInterface {
    attributes: any;
    cards: any;
    colors: any;
    features: any;
    series: any;
}

export const loaderGetOnePieceData: LoaderFunction<LoaderGetOnePieceDataInterface> = async ({ request }: any) => {
    const url = new URL(request.url);
    if (url.pathname === "/registro" || url.pathname === "/login") {
        return null;
    }
    const [cardsResponse, featuresResponse, attributesResponse, seriesResponse, colorsResponse] = await Promise.all([
        axios.get(`${BASE_URL}stocks/stock-filters`, { withCredentials: true }),
        axios.get(`${BASE_URL}features`),
        axios.get(`${BASE_URL}attributes`),
        axios.get(`${BASE_URL}series`),
        axios.get(`${BASE_URL}colors`),
    ]);
    const cardsFetched = cardsResponse.data;
    const features = featuresResponse.data;
    const attributes = attributesResponse.data;
    const series = seriesResponse.data;
    const colors = colorsResponse.data;

    return { cardsFetched, features, attributes, series, colors };
}

export const getPostsByCardId = async (cardId: number): Promise<PostInterface[]> => {
    const resp = await fetch(`${BASE_URL}post/posts/by-card-id?cardId=${cardId}`);
    if (!resp.ok) {
        throw new Error(`Error fetching posts for cardId ${cardId}: ${resp.statusText}`);
    }
    return resp.json();
}

export const paginateCardsWithFilters = async (
    page: string,
    filters: HandlePaginationInterface
): Promise<any> => {
    let url = generateFilterStringUrl(page, filters, "cards/cards-filters");
    const resp = await fetch(url);
    if (!resp.ok) {
        const errorBody = await resp.text();
        throw new Error(`Error fetching posts for cards-filter (status ${resp.status}): ${errorBody}`);
    }
    return resp.json();
}

export const paginateStocksWithFilters = async (
    page: string,
    filters: HandlePaginationInterface
): Promise<any> => {
    let url = generateFilterStringUrl(page, filters, "stocks/stock-filters");
    const resp = await fetch(url);
    if (!resp.ok) {
        const errorBody = await resp.text();
        throw new Error(`Error fetching posts for cards-filter (status ${resp.status}): ${errorBody}`);
    }
    return resp.json();
}

export const paginateAuctionsWithFilters = async (
    page: string,
    filters: HandlePaginationInterface
): Promise<any> => {
    let url = generateFilterStringUrl(page, filters, "auctions/auction-filters");
    const resp = await fetch(url);
    if (!resp.ok) {
        const errorBody = await resp.text();
        throw new Error(`Error fetching posts for cards-filter (status ${resp.status}): ${errorBody}`);
    }
    return resp.json();
}

export const paginateSetsWithoutFilters = async (
    page: string
): Promise<any> => {
    let url = `${BASE_URL}sets?page=${page}`;
    const resp = await fetch(url);
    if (!resp.ok) {
        const errorBody = await resp.text();
        throw new Error(`Error fetching posts for cards-filter (status ${resp.status}): ${errorBody}`);
    }
    return resp.json();
}

export const registerUser = async (userData: CreateUserInterface): Promise<UserInterface> => {
    try {
        setAxiosXsrfToken();
        const response = await axios.post(`${BASE_URL}users`, userData, { withCredentials: true });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const { message, details } = error.response.data;
            throw new CustomError(message, details);
        } else {
            throw new CustomError("Ha ocurrido un error inesperado", "Por favor inténtelo mas tarde");
        }
    }
};

export const generateEmailCode = async (userData: GenerateEmailCodeInterface): Promise<boolean> => {
    try {
        setAxiosXsrfToken();
        const response = await axios.post(`${BASE_URL}activation/generate/email`, userData, { withCredentials: true });
        return response.status === 200;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const { message, details } = error.response.data;
            throw new CustomError(message, details);
        } else {
            throw new CustomError("Ha ocurrido un error inesperado", "Por favor inténtelo mas tarde");
        }
    }
};

export const validateEmailCode = async (userData: ValidateEmailCode): Promise<boolean> => {
    try {
        setAxiosXsrfToken();
        const response = await axios.post(`${BASE_URL}activation/validate/email`, userData, { withCredentials: true });
        return response.status === 200;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const { message, details } = error.response.data;
            throw new CustomError(message, details);
        } else {
            throw new CustomError("Ha ocurrido un error inesperado", "Por favor inténtelo mas tarde");
        }
    }
};

export const generateSmsCode = async (userData: GenerateSmsCodeInterface): Promise<boolean> => {
    try {
        setAxiosXsrfToken();
        const response = await axios.post(`${BASE_URL}activation/generate/sms`, userData, { withCredentials: true });
        return response.status === 200;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const { message, details } = error.response.data;
            throw new CustomError(message, details);
        } else {
            throw new CustomError("Ha ocurrido un error inesperado", "Por favor inténtelo mas tarde");
        }
    }
};
export const validatesSmsCode = async (userData: ValidateSmsCodeInterface): Promise<boolean> => {
    try {
        setAxiosXsrfToken();
        const response = await axios.post(`${BASE_URL}activation/validate/sms`, userData, { withCredentials: true });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const { message, details } = error.response.data;
            throw new CustomError(message, details);
        } else {
            throw new CustomError("Ha ocurrido un error inesperado", "Por favor inténtelo mas tarde");
        }
    }
};

export const login = async (userData: LoginRequestInterface): Promise<boolean> => {
    try {
        setAxiosXsrfToken();
        const response = await axios.post(`${BASE_URL}users/login`, userData, { withCredentials: true });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const { message, details } = error.response.data;
            throw new CustomError(message, details);
        } else {
            throw new CustomError("Ha ocurrido un error inesperado", "Por favor inténtelo mas tarde");
        }
    }
};
export const validateJwt = async (): Promise<UserAuthTokenResponse> => {
    try {
        setAxiosXsrfToken();
        const response = await axios.get(`${BASE_URL}auth/validate-token`, { withCredentials: true });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const { message, details } = error.response.data;
            throw new CustomError(message, details);
        } else {
            throw new CustomError("Ha ocurrido un error inesperado", "Por favor inténtelo mas tarde");
        }
    }
};

export class CustomError extends Error {
    details: string;
    isError = true;
    constructor(message: string, details: string) {
        super(message);
        this.details = details;
    }
}

export const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
}

const setAxiosXsrfToken = () => {
    const token = getCookie('XSRF-TOKEN');
    if (token) {
        axios.defaults.headers.common['X-XSRF-TOKEN'] = token;
    }
};

const generateFilterStringUrl = (page: string, filters: HandlePaginationInterface, serviceUrl: string) => {
    const { attributesIds, colorId, counter, featureId, name, serieId } = filters;
    let url = `${BASE_URL}${serviceUrl}?page=${page}`;
    if (colorId) url += `&colorId=${colorId}`;
    if (name && name.trim()) url += `&name=${name.trim()}`;
    if (featureId) url += `&featureId=${featureId}`;
    if (serieId) url += `&serieId=${serieId}`;
    if (counter) url += `&counter=${counter}`;
    if (attributesIds) url += `&attributes=${attributesIds}`;
    return url;
}