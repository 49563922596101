import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AuthRedirect = ({ children }: { children: any }) => {
    const navigate = useNavigate();
    const loggedUser = useSelector((state: any) => state.user);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        if (Object.keys(loggedUser).length > 0) {
            navigate("/");
        } else {
            setLoading(false);
        }
    }, [loggedUser, navigate]);

    if (loading) {
        return <CircularProgress />;
    }

    return children;
}

export default AuthRedirect;