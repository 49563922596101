import { Button, Grid, Link, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { registerUser } from '../../services/api';
import { UserInterface } from '../../utils/models';

interface PersonalDataRegisterFormProps {
    nextStep: (user: UserInterface, password: string) => void;
    onError: (error: string, errorMessage: string) => void;
}

function PersonalDataRegisterForm({ nextStep, onError }: PersonalDataRegisterFormProps) {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const theme = useTheme();
    const [formData, setFormData] = useState({
        username: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
    });
    const [isLoading, setLoading] = useState(false);

    const [errors, setErrors] = useState({
        username: false,
        firstName: false,
        lastName: false,
        password: false,
        confirmPassword: false,
        passwordMatch: false,
    });

    const handleChange = async (e: any) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        if (!executeRecaptcha) {
            console.log("Recaptcha no está disponible");
            setLoading(false);
            return;
        }
        const token = await executeRecaptcha();
        const newErrors = {
            username: formData.username === '',
            firstName: formData.firstName === '',
            lastName: formData.lastName === '',
            password: formData.password === '',
            confirmPassword: formData.confirmPassword === '',
            passwordMatch: formData.password !== formData.confirmPassword,
        };
        setErrors(newErrors);
        if (Object.values(newErrors).some((error) => error)) {
            setLoading(false);
            return;
        }
        try {
            const user = await registerUser({
                username: formData.username,
                name: formData.firstName,
                lastName: formData.lastName,
                password: formData.password,
                recaptchaToken: token
            });
            setLoading(false);
            nextStep(user, formData.password);
        } catch (err: any) {
            setLoading(false);
            onError(err.message, err.details);
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Nombre de Usuario"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                            error={errors.username}
                            helperText={errors.username ? 'El nombre de usuario es obligatorio' : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Nombre"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                            error={errors.firstName}
                            helperText={errors.firstName ? 'El nombre es obligatorio' : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Apellido"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                            error={errors.lastName}
                            helperText={errors.lastName ? 'El apellido es obligatorio' : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Contraseña"
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                            error={errors.password}
                            helperText={errors.password ? 'La contraseña es obligatoria' : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Verificar Contraseña"
                            type="password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                            error={errors.confirmPassword || errors.passwordMatch}
                            helperText={
                                errors.confirmPassword
                                    ? 'La confirmación de la contraseña es obligatoria'
                                    : errors.passwordMatch
                                        ? 'Las contraseñas no coinciden'
                                        : ''
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            disabled={isLoading}
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: theme.spacing(2) }}
                        >
                            Siguiente
                        </Button>
                    </Grid>
                </Grid>
                <Typography variant="body2" component="span" >¿Ya tienes cuenta? <Link href="/login">Inicia sesión aquí</Link></Typography>
            </form>
        </motion.div >
    );
}

export default PersonalDataRegisterForm;
