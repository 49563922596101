import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Accordion, AccordionDetails, AccordionSummary, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AttributesInterface } from "../../utils/models";


interface FilterAttributeProps {
    attributes: AttributesInterface[];
    selectedAttributes: string;
    onChange: (values: string) => void;
}

const FilterAttribute = ({ attributes, onChange }: FilterAttributeProps) => {

    const [formats, setFormats] = useState(() => ['bold', 'italic']);

    const handleFormat = (
        _event: React.MouseEvent<HTMLElement>,
        newFormats: string[],
    ) => {
        if (formats !== newFormats) {
            setFormats(newFormats);
        }
    };

    useEffect(() => {
        onChange(formats.slice(2).join(","));
    }, [formats]);

    return (
        <Accordion elevation={0} style={{ marginTop: 10, borderRadius: 4, border: 4 }}>
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
            >
                <Typography>Atributos</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ToggleButtonGroup
                    value={formats}
                    onChange={handleFormat}
                    color="primary"
                    aria-label="Platform"
                >
                    <Stack
                        spacing={{ xs: 1, sm: 2 }}
                        direction="row"
                        useFlexGap
                        sx={{ flexWrap: 'wrap' }}
                    >
                        {attributes && attributes?.map(attr => <ToggleButton key={attr.id} style={styles.toogleButton} size="small" value={attr.id}>{attr.name}</ToggleButton>)}
                    </Stack>
                </ToggleButtonGroup>
            </AccordionDetails>
        </Accordion>
    )
}

const styles: { [key: string]: React.CSSProperties } = {
    toogleButton: {
        fontSize: "xx-small",
        width: "max-content",
        height: "max-content",
        padding: "2px"
    }
}

export default FilterAttribute;