import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography
} from '@mui/material';

interface ErrorDialogProps {
    open: boolean;
    onClose: () => void;
    errorName: string;
    errorDescription: string;
}

const ErrorDialog = ({ open, onClose, errorName, errorDescription }: ErrorDialogProps) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <Typography variant="h6">{errorName}</Typography>
                <Typography variant="body1" style={{ marginTop: '16px' }} dangerouslySetInnerHTML={{ __html: errorDescription }}></Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorDialog;
