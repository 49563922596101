import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { IconButton, ListItem, ListItemText } from "@mui/material";
import { PostInterface } from "../../utils/models";

interface SellerItemListProps {
    post: PostInterface;
}

const SellerItemList = ({ post }: SellerItemListProps) => {
    return (
        <ListItem
            secondaryAction={
                <IconButton edge="end" aria-label="delete">
                    <AddShoppingCartIcon />
                </IconButton>
            }
        >
            <ListItemText
                primary={`${post.username} ${post.price}`}
            />
        </ListItem>
    )
}

export default SellerItemList;