import {
    Box,
    Button,
    Grid,
    LinearProgress,
    TextField,
    Typography
} from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { generateSmsCode, validatesSmsCode } from "../../services/api";
import { UserInterface } from "../../utils/models";

interface GenerateSmsCodeProps {
    user?: UserInterface,
    onError: (message: string, details: string) => void;
    onRegisterSuccess: (user: any) => void;
}

const GenerateSmsCode = ({ user, onError, onRegisterSuccess }: GenerateSmsCodeProps) => {
    const [code, setCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [errorPhoneNumber, setErrorPhoneNumber] = useState(false);
    const [errorSmsCode, setErrorSmsCode] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [waitToSend, setWaitToSend] = useState(0);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const totalDuration = 30 * 1000;
        const intervalDuration = 100;
        const incrementAmount = 100 / (totalDuration / intervalDuration);
        if (codeSent) {
            const timer = setInterval(() => {
                setWaitToSend((oldProgress) => {
                    if (oldProgress >= 100) {
                        clearInterval(timer);
                        return 100;
                    }
                    return Math.min(oldProgress + incrementAmount, 100);
                });
            }, intervalDuration);
            return () => {
                clearInterval(timer);
            };
        }
    }, [codeSent]);

    const handleSubmitSms = (event: any) => {
        event.preventDefault();
        setErrorPhoneNumber(false);
        const isValid = /^\d{9}$/.test(phoneNumber);
        if (isValid) {
            handleSendSms();
        } else {
            setErrorPhoneNumber(true);
        }
    };

    const handleSubmitValidateSms = (event: any) => {
        event.preventDefault();
        setErrorSmsCode(false);
        const isValid = /^\d{6}$/.test(code);
        if (isValid) {
            handleValidateSms();
        } else {
            setErrorSmsCode(true);
        }
    }

    const handleSendSms = async () => {
        setCodeSent(true);
        setWaitToSend(0);
        try {
            const resp = await generateSmsCode({
                phoneNumber: '+56' + phoneNumber,
                username: user?.username || ''
            });
            setCodeSent(resp);
            setLoading(false);
        } catch (error: any) {
            setCodeSent(false);
            onError(error.message, error.details);
            setLoading(false);
        }
    }

    const handleValidateSms = async () => {
        try {
            const resp = await validatesSmsCode({
                code: code
            });
            if (resp) onRegisterSuccess(resp);
        } catch (error: any) {
            onError(error.message, error.details);
        }
        setLoading(false);
    }

    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                }}
            >
                <Typography variant="body1" gutterBottom>
                    {codeSent ? 'Te hemos enviado un código de verificación de 6 dígitos. Por favor, ingrésalo a continuación.' : 'Para completar el registro, es necesario validar su número de teléfono. '}
                </Typography>
                <form onSubmit={codeSent ? handleSubmitValidateSms : handleSubmitSms}>
                    <Grid container spacing={2}>
                        {!codeSent &&
                            <>
                                <Grid item xs={2}>
                                    <TextField
                                        label="+56"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{ maxLength: 4 }}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <TextField
                                        label="Número de teléfono"
                                        variant="outlined"
                                        fullWidth
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        inputProps={{ maxLength: 9 }}
                                        error={errorPhoneNumber}
                                        helperText={errorPhoneNumber ? "Por favor introduzca un número de teléfono válido" : ""}
                                    />
                                </Grid>
                            </>
                        }
                        {
                            codeSent &&
                            <Grid item xs={12}>
                                <TextField
                                    label="Código de verificación"
                                    variant="outlined"
                                    fullWidth
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    inputProps={{ maxLength: 6 }}
                                    error={errorSmsCode}
                                    helperText={errorSmsCode ? "Por favor introduzca un código válido." : ""}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{ mt: 2 }}
                            >
                                {codeSent ? 'Validar' : 'Enviar código'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                {
                    codeSent &&
                    <>
                        <Button disabled={isLoading || waitToSend < 100} onClick={handleSendSms}>
                            Volver a enviar código
                        </Button>
                        <Box sx={{ width: '50%' }}>
                            <LinearProgress variant="determinate" value={waitToSend} />
                        </Box>
                    </>

                }
            </Box>
        </motion.div>
    );
};

export default GenerateSmsCode;
