import { Card, CardContent, CardHeader, CardMedia, Chip, Grid, Tooltip, Typography } from "@mui/material";
import React from "react";
import { IMAGE_BASE_URL } from "../../utils/const";
import { CardsInterface } from "../../utils/models";

export interface CardContentInfoProps {
    card: CardsInterface;
    openDialog?: (card: any) => void;
    isEsp: boolean;
}

const CardContentInfo = ({ card, openDialog, isEsp }: CardContentInfoProps) => {
    return (
        <Grid item container spacing={3} md={4} lg={3} xs={12} sm={6} alignContent="center" justifyContent="center" direction="column">
            <Card onClick={() => openDialog && openDialog(card)} variant="outlined" style={{ width: "100%", height: "100%" }}>
                <React.Fragment>
                    <CardHeader />
                    <CardMedia
                        component="img"
                        height="400"
                        image={`${IMAGE_BASE_URL}${card.images}`}
                        alt="Card"
                        style={{ objectFit: "scale-down" }}
                    />
                    <CardContent>
                        <Typography sx={{ textAlign: { xs: "center", md: "initial" } }} variant="h5" component="div">
                            {card.name}
                        </Typography>
                        <br />
                        <Typography component="div" style={{ float: "left" }} sx={{ fontSize: 14, textAlign: { xs: "center", md: "initial" }, width: { xs: "100%", md: "initial" } }} color="text.secondary" gutterBottom>
                            {card.attributes.map(attr => (
                                <Tooltip key={attr.id} title={attr.description}>
                                    <Chip style={{ margin: 2, marginLeft: 0 }} label={attr.name} variant="outlined" />
                                </Tooltip>
                            ))}
                        </Typography>
                        <br />
                        <br />
                        <Typography component="div" variant="body2">
                            <div dangerouslySetInnerHTML={{ __html: isEsp ? card.effectEsp : card.effect }}></div>
                        </Typography>
                    </CardContent>
                </React.Fragment>
            </Card>
        </Grid>
    )
}

export default CardContentInfo;