import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface FilterCounterProps {
    onChange: (value: string) => void;
    selectedCounter: string;
}

const FilterCounter = ({ onChange, selectedCounter }: FilterCounterProps) => {

    const counters = ["-", "1000", "2000"];

    return (
        <FormControl style={{ marginTop: 10 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Counter</InputLabel>
            <Select
                value={selectedCounter || ""}
                onChange={(evt: any) => onChange(evt.target.value)}
                label="counter"
            >
                <MenuItem key="all" value="">Todos</MenuItem>
                {counters.map((counter) => <MenuItem key={counter} value={counter}>{counter}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export default FilterCounter;
