import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import AuthRedirect from './components/auth/AuthRedirect';
import './index.css';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import Buy from './screens/buy';
import Cart from './screens/cart';
import Faq from './screens/faq';
import Login from './screens/login';
import ErrorPage from './screens/not-found';
import Register from './screens/register';
import Sell from './screens/sell';
import { loaderGetOnePieceData } from './services/api';


const router = createBrowserRouter([
  {
    path: "/",
    id: "root",
    element: <App />,
    errorElement: <ErrorPage />,
    loader: loaderGetOnePieceData,
    children: [
      {
        path: "",
        element: <Buy />
      },
      {
        path: "comprar",
        element: <Buy />
      }, {
        path: "vender",
        element: <Sell />
      }, {
        path: "faq",
        element: <Faq />
      }, {
        path: "carro",
        element: <Cart />
      }, {
        path: "registro",
        element:
          <AuthRedirect >
            <Register />
          </AuthRedirect>
      }, {
        path: "login",
        element: <AuthRedirect>
          <Login />
        </AuthRedirect>
      }
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
