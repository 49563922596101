import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { SeriesInterface } from "../../utils/models";

interface FilterSerieProps {
    series: SeriesInterface[];
    onChange: (value: number) => void;
    selectedSerie: number;
}

const FilterSerie = ({ series, onChange, selectedSerie }: FilterSerieProps) => {

    return (
        <FormControl style={{ marginTop: 10 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Serie</InputLabel>
            <Select
                value={selectedSerie || ""}
                onChange={(evt: any) => onChange(evt.target.value)}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Serie"
            >
                <MenuItem key="all" value="">Todas</MenuItem>
                {series && series?.map((serie) => <MenuItem key={serie.id} value={serie.id}>{serie.name}/{serie.type}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export default FilterSerie;