import { List, Paper } from "@mui/material";
import { PostInterface } from "../../utils/models";
import SellerItemList from "./SellerItemList";

interface SellerListProps {
    posts?: PostInterface[];
}

const SellerList = ({ posts }: SellerListProps) => {
    return (
        <Paper>
            <List>
                {posts && posts.map((post) => <SellerItemList post={post} />)}
            </List>
        </Paper>
    )
}

export default SellerList;
