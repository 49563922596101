import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import QuizIcon from '@mui/icons-material/Quiz';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { BottomNavigation, BottomNavigationAction, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import 'materialize-css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import './App.css';
import SigninDialog from './components/dialogs/SignInDialog';


function App() {
  const [isSignInDialogOpen, setSignInDialogOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [isSigndedIn, setSignedIn] = useState(false);
  const theme = useTheme();
  const matchesQuery = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const loggedUser = useSelector((state: any) => state.user);

  useEffect(() => {
    setSignedIn(Object.keys(loggedUser).length > 0);
  }, [loggedUser])

  return (
    <div className="App">
      <Outlet />
      <SigninDialog open={isSignInDialogOpen} onClose={() => setSignInDialogOpen(false)} />
      <BottomNavigation
        style={{ position: "fixed", bottom: 0, width: "100%" }}
        showLabels={false}
        value={tab}
        onChange={(_event, newValue) => {
          setTab(newValue);
        }}
      >
        <BottomNavigationAction value={10} showLabel={false} onClick={() => navigate('/comprar')} label={matchesQuery ? '' : 'Comprar'} icon={<StorefrontIcon />} />
        <BottomNavigationAction onClick={() => navigate('/login')} label={isSigndedIn ? "Cerrar sesión" : "Ingresar"} icon={isSigndedIn ? <LogoutIcon /> : <LoginIcon />} />
        <Tooltip title="En Desarrollo">
          <div>
            <BottomNavigationAction disabled onClick={() => navigate('/vender')} label="Vender" icon={<AttachMoneyIcon />} />
          </div>
        </Tooltip>
        <Tooltip title="En Desarrollo">
          <div>
            <BottomNavigationAction disabled onClick={() => navigate('/carro')} label="Carro" icon={< ShoppingCartIcon />} />
          </div>
        </Tooltip>
        <Tooltip title="En Desarrollo">
          <div>
            <BottomNavigationAction disabled onClick={() => navigate('/faq')} label="Preguntas" icon={<QuizIcon />} />
          </div>
        </Tooltip>
      </BottomNavigation>
    </div>
  );
}

export default App;
