import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ColorsInterface } from "../../utils/models";

interface FilterColorProps {
    colors: ColorsInterface[];
    onChange: (value: number) => void;
    selectedColor: number;
}

const FilterColor = ({ colors, onChange, selectedColor }: FilterColorProps) => {

    return (
        <FormControl style={{ marginTop: 10 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Colores</InputLabel>
            <Select
                value={selectedColor || ""}
                onChange={(evt: any) => onChange(evt.target.value)}
                label="colors"
            >
                <MenuItem key="all" value="">Todos</MenuItem>
                {colors && colors?.map((color) => <MenuItem key={color.id} value={color.id}>{color.name}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export default FilterColor;
