import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, Fab, Grid } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from "react";
import { getPostsByCardId } from "../../services/api";
import { IMAGE_BASE_URL } from "../../utils/const";
import { CardsInterface, PostInterface } from "../../utils/models";
import SellerList from "../sells/SellersList";

export interface SimpleDialogProps {
    open: boolean;
    onClose: (value: string) => void;
    card?: CardsInterface;
}


const CardDialog = ({ onClose, open, card }: SimpleDialogProps) => {

    const [posts, setPosts] = useState<PostInterface[]>();
    const theme = useTheme();
    const matchesQuery = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const getPosts = async () => {
            if (card) {
                try {
                    const posts = await getPostsByCardId(card.id);
                    setPosts(posts);
                } catch (error) {
                    console.error("Error fetching posts:", error);
                }
            }
        };
        getPosts();
    }, [card]);

    return (
        <Dialog fullScreen={matchesQuery} maxWidth="lg" fullWidth onClose={onClose} open={open}>
            <DialogContent>
                <Grid item container>
                    {!matchesQuery && <Grid item xs={(posts && posts.length === 0) ? 12 : 6}>
                        <img
                            src={`${IMAGE_BASE_URL}${card?.images}`}
                            alt={card?.name}
                            loading="lazy"
                            style={{ height: "88vh" }}
                        />
                    </Grid>}
                    {(posts && posts?.length > 0) && (<Grid xs={6}>
                        <SellerList posts={posts} />
                    </Grid>)}
                    <Fab sx={{ display: { xs: 'block', md: 'none' } }} color='primary' onClick={() => onClose("")} size='medium' style={{ bottom: 10, position: "fixed", right: 5 }}>
                        <CloseIcon />
                    </Fab>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default CardDialog;
