import { Button, FormControlLabel, Switch, TextField } from "@mui/material";
import { memo } from "react";
import FilterAttribute from "./FilterAttribute";
import FilterColor from "./FilterColor";
import FilterCounter from "./FilterCounter";
import FilterFeature from "./FilterFeature";
import FilterSerie from "./FilterSerie";

interface FiltersProps {
    onChangeName: (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChangeFeature: (value: number) => void;
    onChangeColor: (value: number) => void;
    onChangeSerie: (value: number) => void;
    onChangeCounter: (value: string) => void;
    onChangeAttributes: (value: string) => void;
    currentFilters: {
        selectColorId: number;
        selectedCardsName: string;
        selectedFeature: number;
        selectedSerie: number;
        selectedCounter: string;
        selectedLanguage: string;
        attributesIds: string;
    };
    colors: any;
    series: any;
    attributes: any;
    features: any;
    onClick: () => void;
    languageValue: boolean;
    onChangeSwitch: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

const Filters = memo((
    {
        onChangeAttributes,
        onChangeColor,
        onChangeCounter,
        onChangeFeature,
        onChangeName,
        onChangeSerie,
        currentFilters,
        attributes,
        colors,
        features,
        series,
        onClick,
        languageValue,
        onChangeSwitch
    }: FiltersProps) => {
    return (
        <>
            <TextField
                value={currentFilters.selectedCardsName}
                onChange={onChangeName}
                style={{ width: "100%", marginTop: 10 }}
                id="outlined-basic"
                label="Buscar por nombre"
                variant="outlined"
            />
            <FilterFeature
                selectedFeature={currentFilters.selectedFeature}
                onChange={onChangeFeature}
                features={features}
            />
            <FilterColor
                selectedColor={currentFilters.selectColorId}
                colors={colors}
                onChange={onChangeColor}
            />
            <FilterSerie
                selectedSerie={currentFilters.selectedSerie}
                onChange={onChangeSerie}
                series={series}
            />
            <FilterCounter
                selectedCounter={currentFilters.selectedCounter}
                onChange={onChangeCounter}
            />
            <FilterAttribute
                selectedAttributes={currentFilters.attributesIds}
                onChange={(value) => onChangeAttributes(value)}
                attributes={attributes}
            />
            <Button
                onClick={onClick}
                fullWidth
                style={{ marginTop: 10 }}
                variant="contained"
            >
                Buscar
            </Button>
            <FormControlLabel
                style={{ float: "right" }}
                control={
                    <Switch
                        checked={languageValue}
                        size="small"
                        onChange={onChangeSwitch}
                        color="primary"
                    />
                }
                label={currentFilters.selectedLanguage}
                labelPlacement="bottom"
            />
        </>
    )
});

export default Filters;