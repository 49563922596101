import { Alert, AlertTitle, Button } from "@mui/material";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../redux/userSlice";

interface SuccessMessageProps {
    title: string;
    message: string;
    withCountDown: number | boolean;
    hideContinueButton?: boolean;
    redirectTo?: string;
    user?: any;
}

const SuccessMessage = ({ title, message, hideContinueButton, withCountDown, redirectTo, user }: SuccessMessageProps) => {
    const [countdown, setCountDown] = useState(withCountDown);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleContinue = useCallback(() => {
        dispatch(setUser(user));
        if (redirectTo !== undefined) {
            navigate(redirectTo);
        }
    }, [dispatch, navigate, redirectTo, user])

    useEffect(() => {
        if (typeof countdown === 'number' && countdown > 0) {
            const timer = setInterval(() => {
                setCountDown(prevSeconds => Number(prevSeconds) - 1);
            }, 1000);
            return () => clearInterval(timer);
        } else {
            handleContinue();
        }
    }, [countdown, handleContinue]);

    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Alert severity="success">
                <AlertTitle>{title}</AlertTitle>
                {message}
                <br />
                {!hideContinueButton && <Button onClick={handleContinue} >Continuar {countdown && countdown}</Button>}
            </Alert>
        </motion.div>
    )
}

export default SuccessMessage;